const URL_LEAD = process.env.GATSBY_BASE_URL + process.env.GATSBY_URL_LEAD
const URL_LEAD_CALL = process.env.GATSBY_BASE_URL + process.env.GATSBY_URL_CALL
const URL_AVIS_REVIEW_DETAILS =
  process.env.GATSBY_BASE_URL + process.env.GATSBY_URL_AVIS_REVIEW_DETAILS
const URL_CREATE_CONTRACT =
  process.env.GATSBY_BASE_URL + process.env.GATSBY_URL_CREATE_CONTRACT_FILL_URL
const URL_CREATE_SIGNATURE =
  process.env.GATSBY_BASE_URL + process.env.GATSBY_URL_CREATE_CONTRACT_SIGN_URL
const URL_CMS = process.env.GATSBY_DRUPAL_URL
const URL_VEL = process.env.VEL_PORTAL_URL2
export default {
  URL_CMS,
  URL_VEL,
  URL_LEAD,
  URL_LEAD_CALL,
  URL_CREATE_CONTRACT,
  URL_AVIS_REVIEW_DETAILS,
  URL_CREATE_SIGNATURE,
}
